export var sliceStringAfterMaxLength = function (maxLength, str) {
    return (str === null || str === void 0 ? void 0 : str.length) > maxLength ? "".concat(str.slice(0, maxLength), "...") : str;
};
export var moneyFormat = function (money, lang) {
    return money === null || money === void 0 ? void 0 : money.toLocaleString("".concat(lang, "-CA"), {
        style: "currency",
        currency: "CAD",
        currencyDisplay: "narrowSymbol"
    });
};
/**
 * Get aggregation data from aggregations array
 *
 * @param aggregationsArray
 * @param indexName
 * @param fieldName
 * @returns {*|*[]}
 */
export var getAggregationData = function (aggregationsArray, indexName, fieldName) {
    var indexAgg = aggregationsArray.find(function (agg) { return agg[indexName] && Object.keys(agg[indexName]).length > 0; });
    return indexAgg && indexAgg[indexName][fieldName]
        ? indexAgg[indexName][fieldName]
        : [];
};
