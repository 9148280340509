import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactPaginate from "react-paginate";
import ProjectsContext from "../../../application/Context/ProjectsContext";
import MarketsContext from "../../../application/Context/MarketsContext";
export var Pagination = function (_a) {
    var application = _a.application;
    var context = application == "markets"
        ? React.useContext(MarketsContext)
        : React.useContext(ProjectsContext);
    var handlePageClick = context.handlePageClick, pageCount = context.pageCount, page = context.page, view = context.view;
    return (_jsx(React.Fragment, { children: view == "list" && pageCount > 1 && (_jsx(ReactPaginate, { previousLabel: "<", nextLabel: ">", breakLabel: "...", breakClassName: "break-me", pageCount: pageCount, marginPagesDisplayed: 2, pageRangeDisplayed: 5, onPageChange: handlePageClick, containerClassName: "pagination", subContainerClassName: "pages pagination", activeClassName: "active", forcePage: page })) }));
};
