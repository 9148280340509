var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import MarketsContext from "../../../../application/Context/MarketsContext";
import ProjectsContext from "../../../../application/Context/ProjectsContext";
import { Mrc, Text, Tag, DateContainer, CompanyImageContainer, IconContainerBig, CompanyDetails, Title, Category, Description, CompanyTitle, } from "../../Styles/SharedStyledComponents";
import { sliceStringAfterMaxLength, moneyFormat } from "../../../../utils/Utils";
import { Row } from "../../shared-components/Ui/Row";
export var ProjectsCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var item = _a.item;
    var projectsJesuisJechercheTypes = (item.is_avis ? React.useContext(ProjectsContext) : React.useContext(MarketsContext)).projectsJesuisJechercheTypes;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var urlPrefix = item.is_avis ? langCode == "fr" ? "/fr/avis" : "/en/notices" : langCode == "fr" ? "/fr/marches" : "/en/markets";
    var f = Intl.DateTimeFormat(langCode, {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
    var projectTypeJSJC = null;
    if (item.project_type === "jesuis_jecherche") {
        var projectJSJCfind = projectsJesuisJechercheTypes.find(function (e) { return e.id === item.type_de_projet_je_suis_je_cherche; });
        projectTypeJSJC = projectJSJCfind ? projectJSJCfind.nom : item.type_de_projet_je_suis_je_cherche;
    }
    return (_jsx(React.Fragment, { children: _jsx(CompanyDetails, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-md-5 ps-md-0 mb-3" }, { children: _jsx("a", __assign({ href: langCode == "fr" ? "".concat(urlPrefix, "/").concat(item.slug_fr, "/") : "".concat(urlPrefix, "/").concat(item.slug_en, "/") }, { children: _jsxs(CompanyImageContainer, __assign({ marginBottom: 14 }, { children: [_jsx("img", { src: ((_c = item.entreprise) === null || _c === void 0 ? void 0 : _c.logo) ? (_d = item.entreprise) === null || _d === void 0 ? void 0 : _d.logo : process.env.IMG_URL + "/static/vendors/images/default-Image-company.png", alt: "banner" }), ((_e = item.entreprise) === null || _e === void 0 ? void 0 : _e.badge) && (_jsx(IconContainerBig, { children: _jsx("img", { src: item.entreprise.badge, width: 32, height: 32, alt: item.type_company ? item.type_company : "company badge" }) }))] })) })) })), _jsx("div", __assign({ className: "col-md-7 mb-3 mb-md-0" }, { children: _jsxs(Description, { children: [_jsx(Title, { children: _jsx("a", __assign({ href: langCode == "fr" ? "".concat(urlPrefix, "/").concat(item.slug_fr, "/") : "".concat(urlPrefix, "/").concat(item.slug_en, "/") }, { children: langCode == "fr" && item.nom_fr ? item.nom_fr : langCode == "en" && item.nom_en ? item.nom_en : item.nom_fr })) }), item.adresse && item.adresse.mrc && (_jsxs(Mrc, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/building.svg"), alt: "building" }), _jsx("span", { children: item.adresse.mrc.nom })] })), _jsx(CompanyTitle, { children: langCode === 'fr'
                                        ? (item.entreprise.nom_legal_fr ? item.entreprise.nom_legal_fr : item.entreprise.nom_legal_en)
                                        : (item.entreprise.nom_legal_en ? item.entreprise.nom_legal_en : item.entreprise.nom_legal_fr) }), _jsxs(Category, { children: [(_g = (_f = item.entreprise) === null || _f === void 0 ? void 0 : _f.code_scian_primaire) === null || _g === void 0 ? void 0 : _g.map(function (scian) {
                                            return (_jsx("li", { children: scian.titre }, scian.id));
                                        }), (_j = (_h = item.entreprise) === null || _h === void 0 ? void 0 : _h.code_scian_secondaire) === null || _j === void 0 ? void 0 : _j.map(function (secscian) {
                                            return (_jsx("li", { children: secscian.titre }, secscian.id));
                                        })] }), _jsxs(Text, { children: [item.montant && (_jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/cash.svg"), alt: "cash" }), _jsx("span", { children: moneyFormat(item.montant, langCode) })] })), _jsx(DateContainer, { children: item.is_avis == true ?
                                                item.date_publication_avis && item.date_fermeture_avis ? "".concat(f.format(new Date(item.date_publication_avis)), " - ").concat(f.format(new Date(item.date_fermeture_avis))) :
                                                    item.date_publication_avis ? "".concat(f.format(new Date(item.date_publication_avis))) :
                                                        "".concat(f.format(new Date(item.date_creation))) :
                                                item.date_publication_avis && item.date_fermeture_avis ? "".concat(f.format(new Date(item.date_publication_avis)), " - ").concat(f.format(new Date(item.date_fermeture_avis))) :
                                                    item.date_publication_avis ? "".concat(f.format(new Date(item.date_publication_avis))) :
                                                        "".concat(f.format(new Date(item.date_creation))) }), item.description_fr && item.description_fr != "None" && item.description_en && item.description_en != "None" && (_jsx("span", __assign({ className: "d-block mb-2" }, { children: sliceStringAfterMaxLength(120, langCode == "fr" ? item.description_fr : item.description_en) }))), item.adresse_to_str && (_jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/map-marker-blue-fonce.svg"), alt: "location" }), _jsx("span", { children: item.adresse_to_str })] })), _jsxs("div", { children: [item.is_avis && item.categorie_projet && (_jsxs(_Fragment, { children: [langCode === "fr" && item.categorie_projet.nom_fr && (_jsx(Tag, { children: item.categorie_projet.nom_fr })), langCode === "en" && item.categorie_projet.nom_en && (_jsx(Tag, { children: item.categorie_projet.nom_en }))] })), !item.is_avis && item.project_type === "jesuis_jecherche" && (_jsx(Tag, { children: projectTypeJSJC })), !item.is_avis && item.project_type !== "jesuis_jecherche" && (_jsxs(_Fragment, { children: [langCode === "fr" && item.categorie_projet.nom_fr && (_jsx(Tag, { children: item.categorie_projet.nom_fr })), langCode === "en" && item.categorie_projet.nom_en && (_jsx(Tag, { children: item.categorie_projet.nom_en }))] }))] })] })] }) }))] }) }) }));
};
